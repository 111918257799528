<template>
  <div>
    <div class="vd_but">
      <el-button type="primary" plain size="small" @click="addRow()">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 50%">
      <el-table-column label="名称">
        <template slot-scope="scope">
          <span>round &emsp;{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="labelName">
        <template slot-scope="scope">
          <el-select
            size="medium"
            v-model="tableData[scope.$index].value"
            @change="stateChange(scope.$index)"
            placeholder="请选择"
            filterable
          >
            <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="danger" plain size="small" @click="delBom(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'SubtableList',
  props: {
    tableData: { type: Array, required: true },
    labelName: { type: String, required: true }
  },
  data() {
    return {
      stateList: [
        { id: 0, label: '未经历' },
        { id: 1, label: '已完成' },
        { id: 2, label: '不经历' },
        { id: 3, label: '正在经历' }
      ]
    };
  },
  methods: {
    addRow() {
      this.$emit('addRow');
    },
    delBom(val) {
      this.$emit('delBom', val);
    },
    stateChange(val) {
      this.$emit('stateChange', val, this.tableData[val].value);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_but {
  margin-bottom: 10px;
}
</style>
